import { useState } from "react";
import { Form, message } from "antd";
import { Link, useNavigate } from "react-router-dom";

import AuthLayout from "../../components/layouts/auth-layout";
import { Input } from "../../components/form-fields";
import ArrowLeft from "../../assets/images/arrow-left.svg";
import { api } from "../../api";
import CustomButton from "../../components/button";
import { getApiErrorMsg } from "../../utils/object-util";

import styles from "./forgotPassword.module.scss";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);

  const handleForgotPassword = (values: any) => {
    setSubmitting(true);
    api
      .post({
        path: "/users/forgot-password",
        service: "auth",
        formdata: {
          email: values.email.toLowerCase(),
        },
      })
      .then((response) => {
        navigate("/forgot-password-success", { replace: true , state:{
          message : response?.data?.message,
        }});
      })
      .catch((err) => {
        message.error({
          content: getApiErrorMsg(err),
          key: "forgot-password",
          duration: 3,
        });
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <AuthLayout>
      <div className={styles["form-container"]}>
        <p className={styles["title"]}>Forgot password?</p>
        <p className={styles["desc"]}>
          Don’t worry! Happens to the best of us.
        </p>

        <Form
          name="forgot-password-form"
          initialValues={{}}
          disabled={submitting}
          onFinish={handleForgotPassword}
          requiredMark={false}
          labelCol={{ span: 24 }}
          validateTrigger={["onBlur", "onChange"]}
        >
          <Form.Item
            label=" Enter your email"
            name="email"
            rules={[
              { required: true, message: "Please input your email!" },
              { type: "email", message: "Please enter valid email!" },
            ]}
          >
            <Input placeholder="Enter Email Address" />
          </Form.Item>

          <CustomButton
            loading={submitting}
            type="primary"
            htmlType="submit"
            className={styles["submit-btn"]}
          >
            Request password reset
          </CustomButton>
        </Form>
        <div className={styles["back-to-login-btn"]}>
          <Link className={styles["back-to-login"]} to="/login">
            <img src={ArrowLeft} alt="Back to login" />
            Back to Login
          </Link>
        </div>
      </div>
    </AuthLayout>
  );
};

export default ForgotPassword;
