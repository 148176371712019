import { useState } from "react";
import { Empty, Form, message } from "antd";

import { api } from "../../../api";
import { IIdNamePair } from "../../../utils/common-interfaces";
import { Input, Select } from "..";
import { getApiErrorMsg, getApiResponseMsg } from "../../../utils/object-util";
import CustomButton from "../../button";
import Modal from "../../modal";

import styles from "./addSelect.module.scss";

interface IAddSelectProps {
	options: Array<{
		id: string;
		name: string;
	}>;
	onSearch?: (val: string) => void;
	label: string;
	name: string | (string | number)[];
	isRequired?: boolean;
	requiredMessage?: string;
	placeholder?: string;
	canAdd?: boolean;
	apiUrl?: string;
	extraFormValues?: { [key: string]: any };
	isMultiple?: boolean;
	apiName?: string;
	optionFilterProp?: string;
	addSuccessCallback?: (...args: any) => void;
	addSuccessMessage?: string;
	onChange?: (...args: any) => void;
	keyChanged?: string;
	useIdInOptions?: boolean;
}

const AddSelect = (props: IAddSelectProps) => {
	const {
		isMultiple = true,
		options,
		onSearch,
		label,
		name,
		isRequired = false,
		requiredMessage = "Please input skills!",
		placeholder,
		canAdd = true,
		apiUrl,
		apiName,
		extraFormValues,
		optionFilterProp,
		addSuccessCallback,
		addSuccessMessage,
		onChange,
		keyChanged,
		useIdInOptions,
	} = props;

	const form = Form.useFormInstance();

	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [searchedValue, setSearchedValue] = useState("");
	const [newSkillItem, setNewSkillItem] = useState("");

	const getFormData = (value: any) => {
		if (apiName) {
			return {
				...(keyChanged ? { [keyChanged]: value } : {}),
				...(extraFormValues || {}),
			};
		} else
			return {
				...(extraFormValues || {}),
				name: value,
			};
	};

	const handleAddItem = () => {
		setNewSkillItem(searchedValue || "");
		setIsOpen(true);
	};

	const handleOnSearch = (val: string) => {
		setSearchedValue(val);
		if (onSearch) onSearch(val);
	};

	const handleSubmit = (val: string) => {
		if (val?.trim()) {
			setIsLoading(true);

			api
				.post({
					path: apiUrl || "/skills",
					service: "job",
					formdata: getFormData(val),
				})
				.then((response) => {
					const prevValues = form.getFieldValue(name) || [];
					if (addSuccessCallback) {
						addSuccessCallback(response, val, options);
					} else if (isMultiple) {
						form.setFieldValue(name, [...prevValues, response?.data?.data?.name]);
					} else {
						form.setFieldValue(name, response?.data?.data?.name);
					}

					form.validateFields([name]);
					setIsOpen(false);
					setSearchedValue("");
					message.success({
						content: addSuccessMessage ?? getApiResponseMsg(response),
						key: "success skill",
						duration: 2,
					});
				})
				.catch((error) => {
					message.error({
						content: getApiErrorMsg(error),
						key: "error skill",
						duration: 2,
					});
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			form.validateFields(["newSkillItem"]);
		}
	};

	const handleModalClose = () => {
		setIsOpen(false);
		setSearchedValue("");
	};

	const handleModalSubmit = () => {
		// handleSubmit(form.getFieldValue("newSkillItem"));
		handleSubmit(newSkillItem);
	};

	const handleDropdownVisibleChange = (open: boolean) => {
		if (!open) {
			setSearchedValue("");
			if (onSearch) onSearch("");
		}
	};

	return (
		<>
			<Form.Item
				label={label}
				name={name}
				rules={[
					{
						required: isRequired,
						message: requiredMessage,
					},
				]}
			>
				<Select
					mode={isMultiple ? "multiple" : undefined}
					placeholder={placeholder || `Search ${canAdd ? "and add " : ""}your ${label}`}
					dropdownRender={(menu) => (
						<>
							{menu}
							{canAdd && (
								<span onClick={handleAddItem} className={`${styles["add-item"]} cursor-pointer `}>
									{`Add ${label}`}
								</span>
							)}
						</>
					)}
					options={
						options?.map((el: IIdNamePair) => ({
							value: useIdInOptions ? el.id : el.name,
							label: el.name,
						})) || []
					}
					searchValue={searchedValue}
					onSearch={handleOnSearch}
					onFocus={() => setSearchedValue("")}
					onBlur={() => setSearchedValue(searchedValue)}
					popupClassName={styles["select-dropdown"]}
					notFoundContent={canAdd ? <></> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
					onChange={(value) => {
						if (onChange) onChange(value, options);
						setSearchedValue("");
					}}
					onDropdownVisibleChange={handleDropdownVisibleChange}
					optionFilterProp={optionFilterProp}
				/>
			</Form.Item>

			<Modal
				title={`Add ${label}`}
				isOpen={isOpen}
				destroyOnClose
				width="600px"
				zIndex={9999}
				onCancel={handleModalClose}
				bodyStyle={{ paddingBottom: 0 }}
				footer={
					<div className={styles["button-wrapper"]}>
						<CustomButton type="default" onClick={handleModalClose}>
							Cancel
						</CustomButton>
						<CustomButton
							htmlType="submit"
							danger
							loading={isLoading}
							onClick={handleModalSubmit}
							disabled={!newSkillItem}
						>
							Submit
						</CustomButton>
					</div>
				}
			>
				<Input
					placeholder={`Enter ${label}`}
					onChange={(e) => {
						setNewSkillItem(e.target.value);
					}}
					onPressEnter={handleModalSubmit}
					value={newSkillItem ?? undefined}
					autoFocus
				/>
			</Modal>
		</>
	);
};

export { AddSelect };
