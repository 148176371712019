import { useEffect, useMemo, useState, lazy, Suspense } from "react";
import { message, ConfigProvider } from "antd";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import {
	backendRouteObjectType,
	routeObjectType,
	defaultRoutes,
	adminRoutes,
	superAdminPolicies,
	IPolicy,
	IPolicies,
} from "../constants/routes";
import { IUser } from "../constants/user-interfaces";
import { IRole } from "../constants/role-permissions";
import { ApprovalRequestTypes } from "../constants/common-interfaces";
import { api } from "../api";
import Spinner from "../components/spinner";
import { AuthRoute, PrivateRoute } from "../auth";
import { AppContext } from "../hooks";
import { isNotEmptyArray } from "../utils/type-util";
import { getApiErrorMsg } from "../utils/object-util";

// Public Routes
import Login from "./login";
import DefaultPage from "./default";
import NotFound from "../components/not-found";
import ErrorPage from "./error-page";
import TenantSignUp from "./tenant-sign-up";
import ForgotPassword from "./forgot-password";
import ForgotPasswordSuccess from "./forgot-password-success";
import ResetPassword from "./reset-password";
import ResetPasswordSuccess from "./reset-password-success";
import SignUp from "./signup";
import CandidatePublicForm from "./candidate-public-form";
import CalendarApp from "./calendar-app";
import CalendlyPublicSuccess from "./calendly-public-success";
import CalendlyPublicError from "./calendly-public-error";
import TenantEmailVerification from "./tenants/tenant-email-verification";

// Private Routes
const Home = lazy(() => import("./home"));
const AdminSettings = lazy(() => import("./admin-settings"));
const JobRequisition = lazy(() => import("./job-requisition"));
const Finance = lazy(() => import("./finance"));
const Team = lazy(() => import("./team"));
const Projects = lazy(() => import("./projects"));
const ProfileSettings = lazy(() => import("./profile-settings"));
const Tenant = lazy(() => import("./tenants"));
const TimeOff = lazy(() => import("./time-off"));
const Reimbursement = lazy(() => import("./reimbursement"));
const Payroll = lazy(() => import("./payroll"));
const Exits = lazy(() => import("./exits"));
const Analytics = lazy(() => import("./analytics"));
const Assets = lazy(() => import("./assets-allocation"));
const Attendance = lazy(() => import("./attendance"));
const Squads = lazy(() => import("./squads"));
const Tiers = lazy(() => import("./tiers"));
const EventsCalendar = lazy(() => import("./events-calendar"));
const OAuthRedirect = lazy(() => import("./events-calendar/add-calendar/oauth-redirect-handler"));
const Approvals = lazy(() => import("./approvals"));
const AIAssistant = lazy(() => import("./ai-assistant"));
const Feedback = lazy(() => import("./feedbacks"));
const ResourceLibrary = lazy(() => import("./resource-library/index"));

import { IApprovalsCount, ICountryList } from "../utils/common-interfaces";
import { checkScope } from "../utils/function-utils";
import PrivateLayout from "../components/layouts/private-layout";

import Health from "./health";
import SquadLeaderboard from "./home/widget-section/squad-performance-widget/squad-leaderboard";
import Fitbit from "./fitbit";
import UserOnboarding from "./user-onboarding";

const App = () => {
	const [isAuthenticating, setIsAuthenticating] = useState<boolean>(true);
	const [loadingMember, setLoadingMember] = useState<boolean>(false);
	const [user, setUser] = useState<IUser>();
	const [countries, setCountries] = useState<ICountryList>();
	const [authError, setAuthError] = useState<string>("");
	const [contentHeight, setContentHeight] = useState(document?.documentElement?.clientHeight || 0);
	const [contentWidth, setContentWidth] = useState(document?.documentElement?.clientWidth || 0);
	const [isClockedIn, setIsClockedIn] = useState(false);
	const [isClockInAndOutLoading, setIsClockInAndOutLoading] = useState(false);
	const [approvalsCount, setApprovalsCount] = useState<IApprovalsCount>();
	const allRoutes = useMemo(() => ({ ...defaultRoutes, ...adminRoutes }), []);

	useEffect(() => {
		if (user?.id && !user?.is_super && user?.tenant?.status === "Active") {
			const scriptId = "aut-assist-ai-bot";
			const accessToken = localStorage.getItem("user-access-token");
			const script = document.createElement("script");
			script.id = scriptId;
			script.src = `${process.env.REACT_APP_CHATBOT_UI_URL}/chatApp.js?id=autoscal&accessToken=${accessToken}`;
			script.async = true; // Set to true if you want the script to load asynchronously
			document.body.appendChild(script);
			return () => {
				if (document.getElementById(scriptId)) {
					document.body.removeChild(script);
				}
			};
		}
	}, [user]);

	useEffect(() => {
		fetchCountryData();
		const accessToken = localStorage.getItem("user-access-token");
		if (accessToken) {
			setIsAuthenticating(true);
			loadCurrentUser(syncUser);
		} else {
			setIsAuthenticating(false);
			setUser(undefined);
		}
		const callback = () => {
			setContentHeight(document.documentElement.clientHeight);
			setContentWidth(document.documentElement.clientWidth);
		};
		window.addEventListener("resize", callback);
		return () => window.removeEventListener("resize", callback);
	}, []);

	useEffect(() => {
		if (user && !user.is_super && user.tenant.status === "Active") {
			fetchClockedInStatus();
			if (checkScope(user, "APPROVALS", "VIEW")) {
				getApprovalsCount();
			}
		}
	}, [user]);

	const syncUser = (userNewData: IUser) => {
		setUser({ ...user, ...userNewData });
	};

	const syncApprovalsCount = (
		requestType: ApprovalRequestTypes,
		operation: "increase" | "decrease",
		operationCount?: number
	) => {
		if (checkScope(user, "APPROVALS", "VIEW"))
			setApprovalsCount((prev) => {
				if (prev) {
					const { leave_applications, reimbursement_requests, attendance_update_requests } = prev;
					const adjustCount = (count: number, type: ApprovalRequestTypes) => {
						if (requestType !== type) return count;

						if (operation === "increase") {
							return count + (operationCount ?? 1);
						} else if (operation === "decrease" && count > 0) {
							return count - (operationCount ?? 1);
						}

						return count;
					};
					return {
						leave_applications: adjustCount(leave_applications, "timeoff"),
						reimbursement_requests: adjustCount(reimbursement_requests, "reimbursement"),
						attendance_update_requests: adjustCount(attendance_update_requests, "attendance"),
					};
				}
			});
	};

	const loadCurrentUser = (
		syncUser: ((data: IUser) => void) | undefined,
		successMessage?: string,
		hideTeamMemberLoader?: boolean
	) => {
		setAuthError("");
		api
			.get({ path: "/users/me", service: "auth" })
			.then((response) => {
				if (response.data?.data) {
					const isSuperUser = response.data.data.is_super;
					const role_id = response.data.data.role_id;
					let menu: {
						default?: IPolicies;
						admin?: IPolicies;
					} = {};
					if (isSuperUser) {
						menu = { default: superAdminPolicies };
					} else {
						const policies =
							response.data.data.tenant?.roles?.find(
								(el: any) => el.id === response.data.data.role_id
							)?.policies || [];
						const defaultPolicies: Record<string, IPolicy> = {};
						const adminPolicies: Record<string, IPolicy> = {};
						policies.forEach((policy: IPolicy) => {
							if (isNotEmptyArray(policy.scope)) {
								const { service } = policy;
								if (adminRoutes[service]) adminPolicies[service] = policy;
								else if (defaultRoutes[service]) defaultPolicies[service] = policy;
							}
						});
						menu = {
							default: defaultPolicies,
							admin: adminPolicies,
						};
					}
					const currentRole = response.data.data.tenant?.roles?.find(
						(role: IRole) => role.id === role_id
					);
					const policies: Record<string, any> = {};
					if (currentRole?.policies?.length) {
						currentRole.policies.forEach((policy: IPolicy) => {
							policies[policy.service] = policy.scope;
						});
					}

					if (!isSuperUser) {
						(async function () {
							const memberResp = await getMemberDetails(
								response.data.data.team_member_id,
								hideTeamMemberLoader
							);
							if (memberResp?.status === 200 && memberResp?.data) {
								if (syncUser)
									syncUser({
										...response.data.data,
										menu,
										currentRole: { ...currentRole, policies: policies },
										team_member: memberResp.data.data,
									});
								if (successMessage) {
									message.success({
										content: successMessage,
										key: "successfully-signed-up",
										duration: 3,
									});
								}
							} else {
								setAuthError(getApiErrorMsg(memberResp));
							}
						})();
					} else if (syncUser)
						syncUser({
							...response.data.data,
							menu,
							currentRole: { ...currentRole, policies: policies },
						});
				}
			})
			.catch((err) => setAuthError(getApiErrorMsg(err)))
			.finally(() => setIsAuthenticating(false));
	};

	const getMemberDetails = async (memberId: string, hideTeamMemberLoader?: boolean) => {
		if (!hideTeamMemberLoader) {
			setLoadingMember(true);
		}
		const memberResp = await api.get({ path: `/team-members/${memberId}`, service: "job" });
		setLoadingMember(false);
		return memberResp;
	};

	const fetchClockedInStatus = () => {
		setIsClockInAndOutLoading(true);
		api
			.get({ path: `/self/clocked-in-status`, service: "attendance" })
			.then((res) => setIsClockedIn(res.data?.data?.is_clocked_in))
			.catch((err) => message.error(getApiErrorMsg(err)))
			.finally(() => setIsClockInAndOutLoading(false));
	};

	const clockInAndOut = () => {
		setIsClockInAndOutLoading(true);
		api
			.post({
				path: `/self/attendance/clock-in-out`,
				service: "attendance",
				formdata: {
					punch_status: isClockedIn ? 1 : 0,
					location_address: {},
					ip_address: null,
					note: "",
					timezone: "IST",
				},
			})
			.then(() => setIsClockedIn(!isClockedIn))
			.catch((err) => message.error(getApiErrorMsg(err)))
			.finally(() => setIsClockInAndOutLoading(false));
	};

	const getApprovalsCount = () => {
		api
			.get({
				path: `/tenants/${user?.tenant_id}/pending-approval-requests-count`,
				service: "attendance",
			})
			.then((response) => {
				if (response?.data?.data?.pending_approval_requests_count) {
					setApprovalsCount({ ...response?.data?.data?.pending_approval_requests_count });
				}
			})
			.catch((err) => {});
	};

	const getCountries = () => {
		return countries?.map((el) => el.name) || [];
	};

	const getStates = (country: string) => {
		return countries?.find((el) => el.name === country)?.states.map((el) => el.name) || [];
	};

	const getCity = (country: string, state: string) => {
		return (
			countries?.find((el) => el.name === country)?.states.find((el) => el.name === state)
				?.cities || []
		);
	};

	const fetchCountryData = () => {
		const url =
			"https://autoscal.s3.ap-south-1.amazonaws.com/countries-list/countries-master-list.json";

		fetch(url)
			.then((response) => {
				return response?.json();
			})
			.then((data) => setCountries(data))
			// eslint-disable-next-line no-console
			.catch((error) => console.error("Error fetching data:", error));
	};

	const contextValue = useMemo(
		() => ({
			user,
			syncUser,
			contentHeight,
			contentWidth,
			countries,
			getCountries,
			getStates,
			getCity,
			loadCurrentUser,
			isClockedIn,
			isClockInAndOutLoading,
			fetchClockedInStatus,
			clockInAndOut,
			approvalsCount,
			syncApprovalsCount,
		}),
		[
			user,
			syncUser,
			contentHeight,
			contentWidth,
			countries,
			getCountries,
			getStates,
			getCity,
			loadCurrentUser,
			isClockedIn,
			isClockInAndOutLoading,
			fetchClockedInStatus,
			clockInAndOut,
			approvalsCount,
			syncApprovalsCount,
		]
	);

	if (isAuthenticating || loadingMember) {
		return <Spinner wrapperStyle={{
			position: "absolute",
			top: "50%",
			right: "50%",
			transform: "translate(0, -50%)",
		}}/>;
	}

	if (authError) {
		return <ErrorPage mainPage error={authError} />;
	}

	return (
		<ConfigProvider theme={{ token: { fontFamily: "inherit" } }}>
			<AppContext.Provider value={contextValue}>
				<BrowserRouter>
					<Suspense
						fallback={
							<PrivateLayout>
								<Spinner
									wrapperStyle={{
										position: "absolute",
										top: "50%",
										right: "50%",
										transform: "translate(0, -50%)",
									}}
								/>
							</PrivateLayout>
						}
					>
						<Routes>
							{/* Auth Routes */}
							<Route path="/" element={<AuthRoute component={Login} />} />
							<Route path="/tenant-onboarding" element={<AuthRoute component={TenantSignUp} />} />
							<Route path="/login" element={<AuthRoute component={Login} />} />
							<Route path="/forgot-password" element={<AuthRoute component={ForgotPassword} />} />
							<Route
								path="/forgot-password-success"
								element={<AuthRoute component={ForgotPasswordSuccess} />}
							/>
							<Route path="/reset-password" element={<AuthRoute component={ResetPassword} />} />
							<Route
								path="/reset-password-success"
								element={<AuthRoute component={ResetPasswordSuccess} />}
							/>
							<Route path="/signup" element={<AuthRoute component={SignUp} />} />

							{/* Private Routes */}
							{user &&
							!user.is_super &&
							(user.team_member.status === "onboarding" ||
								user.team_member.status === "PendingActivation") ? (
								<Route path={allRoutes["HOME"].route} element={<UserOnboarding />} />
							) : (
								<>
									<Route
										path={allRoutes["HOME"].route}
										element={<PrivateRoute component={Home} />}
									/>
									<Route
										path="/profile-settings"
										element={<PrivateRoute component={ProfileSettings} />}
									/>
									<Route
										path="/squad-leaderboard"
										element={<PrivateRoute component={SquadLeaderboard} />}
									/>
									<Route path="/fitbit" element={<PrivateRoute component={Fitbit} />} />
									<Route
										path="/user-onboarding"
										element={<PrivateRoute showCustomComponent component={UserOnboarding} />}
									/>
									{/* Google Oauth Redirect Path */}
									<Route
										path="/googleoauth"
										element={
											<PrivateLayout>
												<OAuthRedirect provider="google" />
											</PrivateLayout>
										}
									/>
									<Route
										path="/microsoftoauth"
										element={
											<PrivateLayout>
												<OAuthRedirect provider="outlook" />
											</PrivateLayout>
										}
									/>
									{user?.team_member_id && (
										<Route
											path={`/team/members`}
											element={
												<PrivateLayout>
													<Team />
												</PrivateLayout>
											}
										/>
									)}
									{/* Outlook Oauth Redirect Path */}
									{/* <Route path="/microsoftoauth" element={<PrivateLayout><OAuthRedirect provider="outlook"/></PrivateLayout>} /> */}
									{Object.values({ ...user?.menu?.admin, ...user?.menu?.default })?.map(
										(menu: backendRouteObjectType) => {
											const routeEl = allRoutes[menu.service];
											if (routeEl) {
												return recursiveRoutesGenerator(routeEl, user);
											}
										}
									)}
								</>
							)}

							{/* Public paths */}
							<Route path="/candidate-public-form" element={<CandidatePublicForm />} />

							<Route path="/calendly-invite" element={<CalendarApp />} />

							<Route path="/calendly-success" element={<CalendlyPublicSuccess />} />

							<Route path="/calendly-error" element={<CalendlyPublicError />} />

							{/* Tenant email  verification */}
							<Route path="/tenants/verify-email" element={<TenantEmailVerification />} />

							<Route path="/*" element={<PrivateRoute component={NotFound} />} />
						</Routes>
					</Suspense>
				</BrowserRouter>
			</AppContext.Provider>
		</ConfigProvider>
	);
};

const recursiveRoutesGenerator = (route: routeObjectType, user: IUser | undefined) => {
	return (
		<Route
			key={route.service}
			path={route.route}
			element={<PrivateRoute component={getComponentFromType(route.service)} />}
		>
			{Object.values(route.subRoutes || {})?.map(
				(subRoute) =>
					user?.currentRole?.policies?.[subRoute?.service]?.includes("VIEW") &&
					recursiveRoutesGenerator(subRoute, user)
			)}
		</Route>
	);
};

const getComponentFromType = (type: string) => {
	switch (type) {
		case "SETTINGS":
			return AdminSettings;
		case "JOB_REQUISITION":
			return JobRequisition;
		case "PROJECTS":
			return Projects;
		case "REIMBURSEMENTS":
			return Reimbursement;
		case "TENANT":
			return Tenant;
		case "TIERS":
			return Tiers;
		case "FEEDBACK":
			return Feedback;
		case "PAYSLIPS":
			return Finance;
		case "TIME_OFF":
			return TimeOff;
		case "PAYROLL":
			return Payroll;
		case "PEOPLE":
			return Team;
		// case "EXITS":
		// 	return Exits;
		// case "ONBOARDING":
		// 	return Team;
		case "ANALYTICS":
			return Analytics;
		case "ASSETS":
			return Assets;
		case "ATTENDANCE":
			return Attendance;
		case "SQUADS":
			return Squads;
		case "HEALTH":
			return Health;
		case "CALENDAR":
			return EventsCalendar;
		case "APPROVALS":
			return Approvals;
		case "AI_ASSISTANT":
			return AIAssistant;
		case "RESOURCE_LIBRARY":
			return ResourceLibrary;
		default:
			return DefaultPage;
	}
};

export default App;
