import { useCallback, useEffect, useState } from "react";

import { api } from "../../../../api";
import { IIdNamePair } from "../../../../utils/common-interfaces";
import { debounceMethod } from "../../../../utils/function-utils";
import CandidateSkillsForm from "../../../../pages/job-requisition/job-preview/candidate-shortlist-form/candidate-skills-form";
import { AddSelect } from "../../../../components/form-fields";

interface IProps {
	isSubmitFailed: boolean;
}

const SkillEvaluation = (props: IProps) => {
	const { isSubmitFailed } = props;

	const [skillsOptions, setSkillsOptions] = useState<IIdNamePair[]>([]);

	useEffect(() => {
		getSkills("");
	}, []);

	const getSkills = (value: string) => {
		api
			.get({
				path: `/skills?name=${value?.trim()?.toLowerCase() || ""}`,
				service: "job",
			})
			.then((response) => {
				const arr =
					response.data?.data?.filter(
						(el: IIdNamePair, index: number) =>
							response.data.data.findIndex((innerEl: IIdNamePair) => el.name === innerEl.name) ===
							index
					) || [];
				setSkillsOptions(arr);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const skillSearch = useCallback(debounceMethod(getSkills), []);

	return (
		<>
			<AddSelect
				label="Skills"
				name="skills"
				options={skillsOptions?.map((el: IIdNamePair) => ({
					id: el.name,
					name: el.name,
				}))}
				onSearch={skillSearch}
				isRequired
				requiredMessage="Please select skills!"
			/>
			<CandidateSkillsForm isSubmitFailed={isSubmitFailed} />
		</>
	);
};

export default SkillEvaluation;
